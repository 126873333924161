import React, {useContext, useState} from "react";
import {ClueMasterPageLayout, CLUE_MASTER_NAVIGATION_ACTIVE_KEYS, FormGroup} from "../../components/components";
import { Button, Form } from "react-bootstrap";
import { getAllChallenges, createGame } from "../../services/firebase";
import useRefreshableRequest from "../../hooks/useRefreshableRequest";
import useIsLoading from "../../hooks/useIsLoading";
import { LoadingSpinner, GameCreationSummaryModal } from "../../components/components";
import { GameOptions } from "../../entities/game";
import appContent from "../../markdown/app-content";
import {AppAlertsContext} from "../../context/app-alerts-context";
import {getOptionsTabRoute} from "./game/OptionsTab";

const CreatePreviewPage = () => {
  const [gameName, setGameName] = useState('');
  const [selectedChallenge, setSelectedChallenge] = useState('');
  const [isHosted, setIsHosted ] = useState(true)
  const [collectPhones, setCollectPhones] = useState(false);
  const [summaryData, setSummaryData] = useState({});
  const [showSummary, setShowSummary] = useState(false);
  const {isLoading, setIsLoading} = useIsLoading(false);
  const {data: challenges, refreshData, isLoading: isLoadingChallenges} = useRefreshableRequest({
    request: getAllChallenges,
    onError: (e) => popError(e.message),
    defaultDataValue: [],
  });
  const {popError} = useContext(AppAlertsContext);

  const handleChallengeChange = (event) => {
    const challengeID = event.target.value;

    setSelectedChallenge(
      challenges.find(challenge => challenge.id === challengeID),
    );
  }

  const handleCreateGame = async event => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const data = await createGame(
        gameName,
        selectedChallenge,
        {
          [GameOptions.COLLECT_PHONES]: collectPhones,
          [GameOptions.IS_HOSTED]: isHosted,
        }
      );
      setIsLoading(false);
      setSummaryData(data);
      setShowSummary(true);
    } catch (error) {
      setIsLoading(false);
      popError(error.message);
    }
  }

  const handleCloseSummary = () => {
    setShowSummary(false);
    if (summaryData.id) {
      getOptionsTabRoute(summaryData.id);
    }
  }

  return <ClueMasterPageLayout activeID={CLUE_MASTER_NAVIGATION_ACTIVE_KEYS.createGame}>
    <div className="d-flex justify-content-between align-items-center">
      <h1>{appContent.cluemaster.createGame.title}</h1>
      <Button onClick={refreshData}>{appContent.cluemaster.createGame.refreshButton}</Button>
    </div>
    {challenges && (
      <Form onSubmit={handleCreateGame}>
        <FormGroup
          label={appContent.cluemaster.createGame.labels.name}
          subText={appContent.cluemaster.createGame.subtext.name}
          controlId="createGame.GameName"
        >
          <Form.Control
            onChange={(e) => setGameName(e.target.value)}
            value={gameName}
            autoComplete="off"
            required
          />
        </FormGroup>

        <FormGroup
          label={appContent.cluemaster.createGame.labels.challenge}
          subText={appContent.cluemaster.createGame.subtext.challenge(selectedChallenge['trackName(FromTracks)'])}
          controlId="createGame.SelectChallenge"
        >
          <Form.Control
            as="select"
            onChange={handleChallengeChange}
            value={selectedChallenge && selectedChallenge.id}
            required
          >
            <option key="no-value" value=""> </option>
            {challenges.map(challenge => (
              <option key={challenge.id} value={challenge.id}>
                {challenge.name}
              </option>
            ))}
          </Form.Control>
        </FormGroup>

        <FormGroup
          subText={appContent.cluemaster.createGame.subtext.isHosted}
        >
          <Form.Check
            id="isHosted-switch"
            type="switch"
            label={appContent.cluemaster.createGame.labels.isHosted}
            checked={isHosted}
            onChange={(event) => setIsHosted(event.target.checked)}
          />
        </FormGroup>

        <FormGroup
          subText={appContent.cluemaster.createGame.subtext.collectPhone}
        >
          <Form.Check
            id="collectPhoneNumbers-switch"
            type="switch"
            label={appContent.cluemaster.createGame.labels.collectPhone}
            checked={collectPhones}
            onChange={(event) => setCollectPhones(event.target.checked)}
          />
        </FormGroup>

        <Button variant="primary" type="submit">
          {appContent.cluemaster.createGame.buttonText}
        </Button>
      </Form>
    )}
    <LoadingSpinner isLoading={isLoading || isLoadingChallenges} />
    <GameCreationSummaryModal onHide={handleCloseSummary} show={showSummary} summaryData={summaryData} />
  </ClueMasterPageLayout>
}

export default CreatePreviewPage;
